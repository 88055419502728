import { Box } from '@mui/material';

import React, { useEffect, useRef } from 'react';

// import Lottie from 'lottie-web';
import left from './left.json';
import right from './right.json';
import bottom from './bottom.json';
// import lottie from 'lottie-web';
import Lottie, { AnimationItem } from 'lottie-web';
import { buttons } from '../../constants';
import { PopoverButton } from '../../components';

const options = [
  { id: '1', label: 'example' },
  { id: '2', label: 'Field Array' },
  { id: '3', label: 'label-3' },
];

export const Dev = () => {
  const svgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = svgRef.current as HTMLDivElement;
    const animations = [left, right, bottom];
    let currentAnimationIndex = 0;
    let currentAnimationInstance: AnimationItem | null = null;

    const playNextAnimation = () => {
      if (currentAnimationInstance) {
        currentAnimationInstance.destroy();
      }

      const currentAnimation = animations[currentAnimationIndex];
      currentAnimationInstance = Lottie.loadAnimation({
        container,
        animationData: currentAnimation,
        loop: false,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
        },
        renderer: 'svg',
      });

      currentAnimationInstance.addEventListener('complete', () => {
        currentAnimationIndex = (currentAnimationIndex + 1) % animations.length;

        playNextAnimation();
      });
    };

    playNextAnimation();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '19px',
        marginBottom: '85px',
        position: 'relative',
        width: '658px',
        height: '632px',
      }}
    >
      <Box ref={svgRef} />
      {buttons.map((button, index) => (
        <PopoverButton
          key={index}
          text={button.text}
          containerStyles={button.containerStyles}
          variant={'popup'}
          popoverMenuText={button.popoverMenuText}
          popoverPlacement={button.popoverPlacement}
          popoverStyles={button.popoverStyles}
          isClosePopover={false}
        />
      ))}
    </Box>
  );
};
