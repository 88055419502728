import { Layout } from 'components';
import { createBrowserRouter } from 'react-router-dom';

import { DatenschutzPage, Dev, Home, ImpressumPage } from '../pages';
import { ROUTES } from '../constants';

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: ROUTES.home,
        element: <Home />,
      },
      {
        path: ROUTES.dev,
        element: <Dev />,
      },
      {
        path: ROUTES.impressum,
        element: <ImpressumPage />,
      },
      {
        path: ROUTES.datenschutz,
        element: <DatenschutzPage />,
      },
    ],
  },
]);

export default router;
