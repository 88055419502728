import { BlockForDownloadPdfCSS, ButtonCSS, ListCSS, Wrapper } from './styles';
import { Box, Link as MuiLink, List, ListItem, Stack, Typography } from '@mui/material';
import { palette } from '../../theme/foundations';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadPDF } from '../../utils';

export const DatenschutzPage = () => {
  const handleDownload = () => downloadPDF('DatenschutzPage');

  return (
    <Wrapper>
      <Box mb={'30px'}>
        <ButtonCSS onClick={handleDownload} variant={'popup'} endIcon={<DownloadIcon />}>
          Als PDF speichern
        </ButtonCSS>
      </Box>
      <BlockForDownloadPdfCSS>
        <Typography variant={'h4'}>Datenschutzerklärung</Typography>
        <Typography variant={'h5'}>Allgemeiner Hinweis und Pflichtinformationen</Typography>
        <Stack gap={'10px'}>
          <Typography variant={'h6'}>Benennung der verantwortlichen Stelle</Typography>
          <List
            subheader={
              'Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:'
            }
          >
            <ListItem>DEX prime GmbH</ListItem>
            <ListItem>Yary Eumenius-Schulz</ListItem>
            <ListItem>Gärtnerweg 9</ListItem>
            <ListItem>60322 Frankfurt am Main</ListItem>
            <ListItem>
              Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die
              Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen,
              Kontaktdaten o. Ä.).
            </ListItem>
          </List>
        </Stack>
        <Stack gap={'10px'}>
          <Typography variant={'h6'}>Widerruf Ihrer Einwilligung zur Datenverarbeitung</Typography>
          <Typography>
            Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung
            möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für
            den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
            Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </Typography>
        </Stack>

        <Stack gap={'10px'}>
          <Typography variant={'h6'}>
            Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
          </Typography>
          <Typography>
            Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde
            bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des
            Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link
            stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:{' '}
            <MuiLink
              sx={{ color: palette.error.main, overflowWrap: 'anywhere' }}
              target='_blank'
              href={
                'https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'
              }
            >
              https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
            </MuiLink>
          </Typography>
        </Stack>

        <Stack gap={'10px'}>
          <Typography variant={'h6'}>Recht auf Datenübertragbarkeit</Typography>
          <Typography>
            Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
            Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen
            zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die
            direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies
            nur, soweit es technisch machbar ist.
          </Typography>
        </Stack>

        <Stack gap={'10px'}>
          <Typography variant={'h6'}>
            Recht auf Auskunft, Berichtigung, Sperrung, Löschung
          </Typography>
          <Typography>
            Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf
            unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der
            Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum
            aufgeführten Kontaktmöglichkeiten an uns wenden.
          </Typography>
        </Stack>

        <Stack gap={'10px'}>
          <Typography variant={'h6'}>SSL- bzw. TLS-Verschlüsselung</Typography>
          <Typography>
            Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an
            uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung.
            Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar.
            Sie erkennen eine verschlüsselte Verbindung an der https:// Adresszeile Ihres Browsers
            und am Schloss-Symbol in der Browserzeile.
          </Typography>
        </Stack>

        <Stack gap={'10px'}>
          <Typography variant={'h6'}>Server-Log-Dateien</Typography>
          <Typography>
            In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch
            Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:
          </Typography>
          <ListCSS>
            <ListItem>Besuchte Seite auf unserer Domain</ListItem>
            <ListItem>Datum und Uhrzeit der Serveranfrage</ListItem>
            <ListItem>Browsertyp und Browserversion</ListItem>
            <ListItem>Verwendetes Betriebssystem</ListItem>
            <ListItem>Referrer URL</ListItem>
            <ListItem>Hostname des zugreifenden Rechners</ListItem>
            <ListItem>IP-Adresse</ListItem>
          </ListCSS>
          <Typography>
            Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage
            der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
            zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
          </Typography>
        </Stack>

        <Stack gap={'10px'}>
          <Typography variant={'h6'}>Registrierung auf dieser Website</Typography>
          <Typography>
            Zur Nutzung bestimmter Funktionen können Sie sich auf unserer Website registrieren. Die
            übermittelten Daten dienen ausschließlich zum Zwecke der Nutzung des jeweiligen
            Angebotes oder Dienstes. Bei der Registrierung abgefragte Pflichtangaben sind
            vollständig anzugeben. Andernfalls werden wir die Registrierung ablehnen.
          </Typography>
          <Typography>
            Im Falle wichtiger Änderungen, etwa aus technischen Gründen, informieren wir Sie per
            E-Mail. Die E-Mail wird an die Adresse versendet, die bei der Registrierung angegeben
            wurde.
          </Typography>
          <Typography>
            Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten
            Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per
            E-Mail. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf
            unberührt.
          </Typography>
          <Typography>
            Wir speichern die bei der Registrierung erfassten Daten während des Zeitraums, den Sie
            auf unserer Website registriert sind. Ihren Daten werden gelöscht, sollten Sie Ihre
            Registrierung aufheben. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
          </Typography>
        </Stack>
        <Stack gap={'10px'}>
          <Typography variant={'h6'}>Kontaktformular</Typography>
          <Typography>
            Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten
            gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen
            bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.
          </Typography>
          <Typography>
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich
            auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer
            bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
            formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </Typography>
          <Typography>
            Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung
            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der
            Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere
            Aufbewahrungsfristen - bleiben unberührt.
          </Typography>
        </Stack>
      </BlockForDownloadPdfCSS>
    </Wrapper>
  );
};
