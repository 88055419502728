import { Link } from 'react-router-dom';

import { MENU_LINKS } from '../../constants';
import { BgImage, LinksWrapper, StyledFooter } from './styles';

export const Footer = () => {
  return (
    <StyledFooter>
      <BgImage />
      <LinksWrapper>
        {MENU_LINKS.map(({ path, content, subContent, image: Image }, i) => {
          return (
            <Link key={i} to={path}>
              {content} <span>{subContent}</span> {Image && <Image />}
            </Link>
          );
        })}
      </LinksWrapper>
    </StyledFooter>
  );
};
