import { Fade, Popper, SxProps, Typography } from '@mui/material';
import { palette } from '../../../theme/foundations';
import { PopperPlacementType } from '@mui/base/Popper/Popper.types';
import { BoxFadeCSS } from './styles';
import React, { RefObject } from 'react';

interface PopperCustomProps {
  id?: string;
  open: boolean;
  placement: PopperPlacementType;
  anchorEl: HTMLElement | null;
  popperSx?: SxProps;
  wrapperSx?: SxProps;
  popperMenuText?: string;
  popperRef?: RefObject<HTMLDivElement>;
}

export const PopperCustom = ({
  id,
  open,
  placement,
  anchorEl,
  popperSx,
  popperMenuText,
  wrapperSx,
  popperRef,
}: PopperCustomProps) => {
  return (
    <Popper
      ref={popperRef}
      id={id}
      open={open}
      placement={placement}
      anchorEl={anchorEl}
      transition
      sx={{ zIndex: 6, ...wrapperSx }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <BoxFadeCSS sx={popperSx}>
            <Typography variant={'sm'} color={palette.blue600}>
              {popperMenuText?.split('\n').map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </BoxFadeCSS>
        </Fade>
      )}
    </Popper>
  );
};
