import * as yup from 'yup';
import { MESSAGES } from './messages';

export const drittRegisterFormSchema = yup.object().shape({
  email: yup.string().email(MESSAGES.INCORRECT_EMAIL).required(MESSAGES.REQUIRE_MESSAGE),
  password: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password')], MESSAGES.PASS_NOT_MATCH)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
