import { Stack } from '@mui/material';
import { TabsCustom } from '../../UI/TabsCustom/TabsCustom';
import { useState } from 'react';

import { TabsWrapper } from '../styled';
import { LoginForm, RegistrierenForm } from '..';
import { TABS_OPTIONS } from '../../../constants';

const options = [
  { id: TABS_OPTIONS.login, label: 'Login' },
  { id: TABS_OPTIONS.registrieren, label: 'Registrieren' },
];

export const BankForm = () => {
  const [tabValue, setTabValue] = useState(TABS_OPTIONS.login);
  return (
    <Stack>
      <TabsWrapper>
        <TabsCustom value={tabValue} setValue={setTabValue} options={options} />
      </TabsWrapper>
      {tabValue === TABS_OPTIONS.login ? <LoginForm /> : <RegistrierenForm />}
    </Stack>
  );
};
