import { ForgotBtn, FormCSS, LoadingButtonCSS, RowButtonCSS, Wrapper } from '../styled';
import { TextFieldRHF } from '../../UI/TextFieldRHF/TextFieldRHF';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { drittLoginFormSchema } from '../../../validation';
import { useLoginMutation } from '../../../graphql/generated';
import { useAlertContext } from '../../../context';
import { ChevronRightIcon } from '../../icons';

type FormValues = {
  email: string;
  password: string;
};

const INIT_VALUE = {
  email: '',
  password: '',
};

export const LoginDrittForm = () => {
  const { onOpenAlert } = useAlertContext();
  const methods = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(drittLoginFormSchema),
  });
  const { handleSubmit, reset } = methods;

  const [login, { loading }] = useLoginMutation({
    onCompleted: () => {
      reset(INIT_VALUE);
      onOpenAlert({ title: 'Erfolg' });
    },
    onError: (error) => {
      onOpenAlert({ title: 'Aufgetretener fehler', subTitle: error.message, error: true });
    },
  });

  const onSubmitRHF = (value: FormValues) => {
    login({
      variables: {
        input: {
          email: value.email,
          password: value.password,
        },
      },
    });
  };

  const handleForgot = () => {
    console.log('handleForgot');
  };

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitRHF)}>
          <FormCSS>
            <TextFieldRHF
              name={'email'}
              placeholder={'Ihre E-Mail Adresse*'}
              label={'E-Mail Adresse'}
            />
            <TextFieldRHF
              name={'password'}
              type={'password'}
              placeholder={'Ihr Passwort*'}
              label={'Passwort'}
            />
          </FormCSS>
          <RowButtonCSS>
            <LoadingButtonCSS
              type={'submit'}
              variant={'submit'}
              endIcon={<ChevronRightIcon />}
              loading={loading}
              loadingPosition='end'
              sx={{ marginTop: '0 !important' }}
            >
              SIGN IN
            </LoadingButtonCSS>
            <ForgotBtn onClick={handleForgot}>Forgot password?</ForgotBtn>
          </RowButtonCSS>
        </form>
      </FormProvider>
    </Wrapper>
  );
};
