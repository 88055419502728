import { ButtonCSS, Logo, StyledAppBar } from './styles';
import { LogoIcon } from '../icons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';

interface HeaderProps {
  onOpenDrawer: () => void;
}

export const Header = ({ onOpenDrawer }: HeaderProps) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(ROUTES.home);
  };

  return (
    <StyledAppBar>
      <Logo onClick={handleNavigate}>
        <LogoIcon />
      </Logo>
      <ButtonCSS variant='outlined' onClick={onOpenDrawer}>
        Jetzt Kontakt aufnehmen
      </ButtonCSS>
    </StyledAppBar>
  );
};
