import { TabsCustom } from '../UI/TabsCustom/TabsCustom';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { BankForm, ProjektentwicklerForm, DrittsicherheitengeberForm } from '../Forms';
import { TabsWrapper } from './styled';
import { TABS_OPTIONS } from '../../constants';

const options = [
  { id: TABS_OPTIONS.projektentwickler, label: 'Projektentwickler' },
  { id: TABS_OPTIONS.bank, label: 'Bank' },
  { id: TABS_OPTIONS.drittsicherheitengeber, label: 'Drittsicherheitengeber' },
];

export const DrawerContent = () => {
  const [tabValue, setTabValue] = useState(TABS_OPTIONS.projektentwickler);
  return (
    <Stack>
      <TabsWrapper>
        <TabsCustom value={tabValue} setValue={setTabValue} options={options} />
      </TabsWrapper>
      {tabValue === TABS_OPTIONS.projektentwickler ? (
        <ProjektentwicklerForm />
      ) : tabValue === TABS_OPTIONS.bank ? (
        <BankForm />
      ) : (
        <DrittsicherheitengeberForm />
      )}
    </Stack>
  );
};
