export const getPopperMenuText = (index: number) => {
  switch (index) {
    case 0: // CENTER
      return 'Erste B2B-Plattform für Drittsicherheiten';
    case 1: // Entwickler
      return '(Entwickler für Immobilien & Erneuerbare Energien) \n Schließung der Mezzanine-Finanzierungslücke durch Erhöhung des Kreditrahmens des Bankdarlehens. Vereinfachte Finanzierungsstruktur durch One-Stop-Shop-Finanzierung der Partnerbank.';
    case 2: // EIGENTÜMER
      return '(Family-Offices, Stiftungen, Immobiliengesellschaften)  \n Aktivierung des schlummernden Renditepotenzials (Beleihungspotenzial) einer (zum Teil) abbezahlten Immobilie oder Wald- & Agrarfläche über den Miet- bzw. Pachtertrag hinaus. Umfangreicher Schutz der Grundschulden durch mehrstufige Risikominderungsmechanismen durch DEX';
    case 3: // BANKEN
      return '(Banken aktiv in Projektfinanzierungen) \n Mehr Neugeschäft ohne signifikanten Zusatzaufwand. Erhöhung der Rendite auf das hinterlegte regulatorische und ökonomische Risikokapital dank Drittbesicherung.';
    default:
      return '';
  }
};
