import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import bgImage from 'assets/icon/foot-bg.svg';

import { palette } from '../../theme/foundations';

export const StyledFooter = styled('footer')(({ theme }) => ({
  padding: '40px 90px 25px',
  display: 'flex',
  flexShrink: 0,
  background: palette.secondary.main,
  marginTop: 'auto',
  position: 'relative',

  a: {
    color: palette.white,
    textDecoration: 'none',
    fontSize: '14.453px',
    lineHeight: '145%',
    '&:hover': {
      textDecoration: 'none',
    },

    span: {
      textDecoration: 'underline',
      color: palette.blue300,
    },
  },
  [theme.breakpoints.down('md')]: {
    padding: '20px 10px',
  },
  [theme.breakpoints.up('gxl')]: {
    padding: '2.1vw 4.7vw 1.3vw',
  },
}));

export const BgImage = styled(Box)(() => ({
  position: 'absolute',
  height: '130px',
  width: '100%',
  top: '-130px',
  left: 0,
  background: `url(${bgImage}) center center`,
}));

export const LinksWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    gap: '20px',
  },
  [theme.breakpoints.up('gxl')]: {
    svg: {
      width: '1.7vw',
      height: '1.7vw',
    },
    a: {
      fontSize: '0.7vw',
    },
  },
}));
