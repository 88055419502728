import { Stack, Typography } from '@mui/material';
import { BlockForDownloadPdfCSS, Wrapper } from './styles';

export const ImpressumPage = () => {
  return (
    <Wrapper>
      <BlockForDownloadPdfCSS>
        <Stack mb={4} gap={'10px'}>
          <Typography>DEX Prime GmbH</Typography>
          <Typography>Gärtnerweg 9</Typography>
          <Typography>60322 Frankfurt am Main</Typography>

          <Typography>Geschäftsführer: Yary Eumenius-Schulz</Typography>

          <Typography>E-Mail: info@dexprime.com</Typography>
          <Typography>Internet: https://dexprime.com</Typography>

          <Typography>Ust-IdNr :</Typography>
          <Typography>DE348466324 gemäß Umsatzsteuergesetzt §27</Typography>
          <Typography>Amtsgericht: Frankfurt am Main</Typography>
          <Typography>Handelsregister: HRB 123469</Typography>
        </Stack>
        <Typography>
          All right reserved Hinweis nach Telemediengesetz: Für die Inhalte von Websites dritter
          Personen, auf die der Herausgeber dieser Website verlinkt, sind ausschließlich die
          jeweiligen Dritten verantwortlich. Der Herausgeber dieser Website übernimmt keine
          Verantwortung für den Inhalt von Websites dritter Personen. Des Weiteren kann die Website
          des Herausgebers ohne dessen Wissen oder dessen Zustimmung mit anderen Websites verlinkt
          werden. Der Herausgeber übernimmt keine Verantwortung für Darstellungen, Inhalte oder
          irgendeine Verbindungen zur Organisation des Herausgebers in Web -Seiten Dritter. Für
          fremde Inhalte ist der Herausgeber nur dann verantwortlich, wenn er von ihnen (d.h. auch
          von einem rechtswidrigen bzw. strafbaren Inhalt) positive Kenntnis hat und es dem
          Herausgeber technisch möglich und zumutbar ist, deren Nutzung zu verhindern. Der
          Herausgeber ist nach dem Telekommunikationsgesetz (TMG) nicht verpflichtet, die fremden
          Inhalte fortwährend zu überprüfen
        </Typography>
      </BlockForDownloadPdfCSS>
    </Wrapper>
  );
};
