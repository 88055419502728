import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import { palette } from '../../theme/foundations';
import { DRAWER_WIDTH } from '../../constants';

export const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  background: palette.secondary.main,
});

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '96px 90px 24px 90px',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: DRAWER_WIDTH + 'vw',
  }),
  [theme.breakpoints.down('md')]: {
    padding: '96px 10px 24px 10px',
  },
  [theme.breakpoints.up('gxl')]: {
    padding: '5vw 4.69vw 1.25vw',
  },
}));
