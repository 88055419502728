import { LinkedinIcon } from '../components/icons';
import { PopperPlacementType } from '@mui/base/Popper/Popper.types';
import { SxProps } from '@mui/material';

export const ROUTES = {
  home: '/',
  dev: '/dev',
  impressum: '/impressum',
  datenschutz: '/datenschutz',
  linkedin: 'https://www.linkedin.com/company/dexprime/',
};

export const MENU_LINKS = [
  {
    path: ROUTES.home,
    content: 'DEX prime GmbH',
  },
  {
    path: ROUTES.home,
    content: 'Email',
    subContent: 'info@dexprime.com',
  },
  {
    path: ROUTES.impressum,
    content: 'Impressum',
  },
  {
    path: ROUTES.datenschutz,
    content: 'Datenschutz',
  },
  {
    path: ROUTES.linkedin,
    content: '',
    image: LinkedinIcon,
  },
];

// ! 26.51vw or 509px
export const DRAWER_WIDTH = 26.51;

export enum TABS_OPTIONS {
  projektentwickler = 'Projektentwickler',
  bank = 'Bank',
  drittsicherheitengeber = 'Drittsicherheitengeber',
  login = 'Login',
  registrieren = 'Registrieren',
}

export enum POPOVERCONTENT {
  GRANDSCHULDPOPOVERTEXT = 'Abtretung von Grundschulden (Drittsicherheiten) zur Besicherung der Projektfinanzierung basierend auf individuellen Allokationskriterien des Eigentümers.',
  DARLEHENPOPOVERTEXT = 'Erhöhung des Kreditrahmens für die Projektfinanzierung dank Drittsicherheiten.',
  BANKENPOPOVERTEXT = '(Banken aktiv in Projektfinanzierungen) \n Mehr Neugeschäft ohne signifikanten Zusatzaufwand. Erhöhung der Rendite auf das hinterlegte regulatorische und ökonomische Risikokapital dank Drittbesicherung.',
  PRAMIE = 'Risikoadäquate Vergütung des Eigentümers für die Übernahme des Kreditrisikos des Entwicklers.',
  ENTWICKLER = '(Entwickler für Immobilien & Erneuerbare Energien) \n Schließung der Mezzanine-Finanzierungslücke durch Erhöhung des Kreditrahmens des Bankdarlehens. Vereinfachte Finanzierungsstruktur durch One-Stop-Shop-Finanzierung der Partnerbank.',
  EIGENTÜMER = '(Family-Offices, Stiftungen, Immobiliengesellschaften) \n Aktivierung des schlummernden Renditepotenzials (Beleihungspotenzial) einer (zum Teil) abbezahlten Immobilie oder Wald- & Agrarfläche über den Miet- bzw. Pachtertrag hinaus. Umfangreicher Schutz der Grundschulden durch mehrstufige Risikominderungsmechanismen durch DEX',
}

type PopoverButtonsType = {
  text: 'EIGENTÜMER' | 'Grundschuld' | 'BANKEN' | 'Darlehen' | 'PRÄMIE' | 'Entwickler';
  containerStyles: Record<string, string | number | object>;
  isMobileContainerStyles: Record<string, string | number | object>;
  popoverMenuText: string;
  popoverPlacement: PopperPlacementType;
  isMobilePopoverPlacement: PopperPlacementType;
  popoverStyles: SxProps;
  isMobilePopoverStyles: SxProps;
};

export const buttons: PopoverButtonsType[] = [
  {
    text: 'EIGENTÜMER',
    containerStyles: {
      position: 'absolute',
      left: '6%',
      bottom: '32px',
    },
    isMobileContainerStyles: {
      position: 'absolute',
      left: '0',
      bottom: '32px',
      button: { fontSize: '10px ' },
    },
    popoverMenuText: POPOVERCONTENT.EIGENTÜMER,
    popoverPlacement: 'left-end',
    isMobilePopoverPlacement: 'top',
    popoverStyles: { marginRight: '10px' },
    isMobilePopoverStyles: { marginBottom: '10px', marginTop: '10px' },
  },
  {
    text: 'Grundschuld',
    containerStyles: { position: 'absolute', top: '42%', right: '68%' },
    isMobileContainerStyles: {
      position: 'absolute',
      left: '0',
      top: '42%',
      button: { fontSize: '10px ' },
    },
    popoverMenuText: POPOVERCONTENT.GRANDSCHULDPOPOVERTEXT,
    popoverPlacement: 'top-end',
    isMobilePopoverPlacement: 'top-end',
    popoverStyles: { marginBottom: '10px', marginTop: '10px' },
    isMobilePopoverStyles: { marginBottom: '10px' },
  },
  {
    text: 'BANKEN',
    containerStyles: {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
    isMobileContainerStyles: {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
      button: { fontSize: '10px ' },
    },
    popoverMenuText: POPOVERCONTENT.BANKENPOPOVERTEXT,
    popoverPlacement: 'bottom',
    isMobilePopoverPlacement: 'bottom',
    popoverStyles: { marginTop: '10px' },
    isMobilePopoverStyles: { marginTop: '10px' },
  },
  {
    text: 'Darlehen',
    containerStyles: { position: 'absolute', left: '68%', top: '42%' },
    isMobileContainerStyles: {
      position: 'absolute',
      right: '0',
      top: '42%',
      button: { fontSize: '10px ' },
    },
    popoverMenuText: POPOVERCONTENT.DARLEHENPOPOVERTEXT,
    popoverPlacement: 'top-start',
    isMobilePopoverPlacement: 'top-start',
    popoverStyles: { marginBottom: '10px' },
    isMobilePopoverStyles: { marginBottom: '10px' },
  },
  {
    text: 'PRÄMIE',
    containerStyles: {
      position: 'absolute',
      bottom: '20%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    isMobileContainerStyles: {
      position: 'absolute',
      bottom: '78px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      button: { fontSize: '10px ' },
    },
    popoverMenuText: POPOVERCONTENT.PRAMIE,
    popoverPlacement: 'bottom',
    isMobilePopoverPlacement: 'bottom',
    popoverStyles: { marginBottom: '10px', marginTop: '10px' },
    isMobilePopoverStyles: { marginBottom: '10px', marginTop: '10px' },
  },
  {
    text: 'Entwickler',
    containerStyles: { position: 'absolute', right: '6%', bottom: '34px' },
    isMobileContainerStyles: {
      position: 'absolute',
      right: '0',
      bottom: '34px',
      button: { fontSize: '10px ' },
    },
    popoverMenuText: POPOVERCONTENT.ENTWICKLER,
    popoverPlacement: 'right-end',
    isMobilePopoverPlacement: 'top',
    popoverStyles: { marginLeft: '10px' },
    isMobilePopoverStyles: { marginBottom: '10px', marginTop: '10px' },
  },
];
