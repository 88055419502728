import { Button, styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '24px 90px',
  background: 'linear-gradient(162deg, rgba(1, 41, 96, 0.80) 0%, rgba(47, 100, 175, 0.35) 100%)',
  boxShadow: 'none',
  [theme.breakpoints.down('md')]: {
    padding: '20px 10px',
    flexWrap: 'wrap',
  },
  [theme.breakpoints.up('gxl')]: {
    padding: '1.2vw 4.7vw',
  },
}));

export const Logo = styled(Box)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  img: {
    marginBottom: '-4px',
  },
  [theme.breakpoints.down('md')]: {
    svg: {
      width: '370px',
    },
  },
  [theme.breakpoints.up('gxl')]: {
    svg: {
      width: '20vw',
      height: '2vw',
    },
  },
}));

export const ButtonCSS = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('gxl')]: {
    height: '2.5vw',
    fontSize: '0.9vw',
  },
}));
