import { IconButton, Typography } from '@mui/material';
import { CloseIcon } from '../icons';
import { DrawerHeader, DrawerWrapper } from './styled';
import { palette } from '../../theme/foundations';

import { DrawerContent } from '../DrawerContent/DrawerContent';

interface DrawerCustomProps {
  openDrawer: boolean;
  onDrawerClose: () => void;
}

export const DrawerCustom = ({ openDrawer, onDrawerClose }: DrawerCustomProps) => {
  return (
    <DrawerWrapper variant='persistent' anchor='right' open={openDrawer}>
      <DrawerHeader>
        <Typography
          variant={'body2'}
          color={palette.white}
          textTransform={'uppercase'}
          fontWeight={700}
        >
          Jetzt Kontakt aufnehmen
        </Typography>
        <IconButton onClick={onDrawerClose}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent />
    </DrawerWrapper>
  );
};
